@import "../styles/spacing.scss";
@import "../styles/typography.scss";

.main {
	height: 100vh;
	height: 100svh;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	color: white;
	background: black;
}

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: $spacer;
}

.column:first-child {
	border-right: 1px solid white;
}

.column {
	h2 {
		@include text-3xl;
		margin-bottom: $spacer-xl;
	}
	p {
		display: flex;
		align-items: center;
		gap: $spacer-sm;
		@include text-base;
		text-align: center;
		margin-bottom: $spacer-md;
		button {
			text-decoration-line: underline;
			text-underline-offset: 4px;
		}
	}
	.checkbox {
		display: inline-flex;
	}
}

.terms {
	max-width: 850px;
	text-align: left;
	@include text-base;
	h3 {
		margin-bottom: $spacer-sm;
	}
}
