.main {
	display: flex;
	position: relative;
	min-height: 100vh;
	min-height: 100svh;
	background-color: black;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.logo {
	display: block;
	width: 60%;
	margin-bottom: 2em;
}
