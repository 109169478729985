@import "../styles/global.scss";

.button {
	@include druk;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: black;
	font-size: 26px;
	line-height: 1em;
	letter-spacing: 0.04846154em;
	text-transform: uppercase;
	border-radius: 9999px;
	background: white;
	cursor: pointer;
	padding: 0.7em 1.5em 0.65em;

	&.disabled {
		background: #404040;
	}

	&.placeholder {
		background: none;
		color: $yellow;
		border: 2px dashed $yellow;
	}

	@media (min-width: $q-breakpoint) {
		font-size: 42px;
	}
	&.light {
		background: white;
		color: black;
	}
}
