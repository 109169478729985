@import "../styles/typography.scss";

.main {
	--gutter-x: 20px;
	--gutter-y: 20px;

	--spacer-sm: 15px;
	--spacer-md: 30px;
	--spacer-lg: 40px;

	--max-width: 1120px;
	--progress-height: 90px;
	--progress-bullet-size: 8px;
	--sidebar-width: 80px;
	--head-height: 80px;
	--button-height: 44px;
	height: 100%;
	min-height: 100vh;
	min-height: 100svh;
	color: white;
	background-image: url("../assets/texture-mobile.jpg");
	background-size: cover;
	background-position: bottom center;
	background-color: black;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: var(--spacer-sm);
	padding: var(--gutter-y) var(--gutter-x);
	padding-bottom: calc(var(--button-height) + (var(--gutter-y) * 2));
	text-align: center;

	@media (min-width: $q-breakpoint) {
		--gutter-x: 100px;
		--gutter-y: 50px;

		--spacer-sm: 30px;
		--spacer-md: 60px;
		--spacer-lg: 80px;

		--progress-height: 120px;
		--progress-bullet-size: 8px;
		--head-height: 160px;
		--button-height: 90px;

		padding: var(--spacer-sm) var(--gutter-x);
		gap: var(--spacer-md);
		background-position: bottom right;
		background-image: url("../assets/texture.jpg");
	}
}

.heading {
	text-align: center;
	margin-bottom: 1em;
	> svg {
		width: 100%;
		display: inline-block;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		> svg {
			width: 75%;
		}
	}
}

.items {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: var(--spacer-sm);
	width: 100%;
	max-width: 640px;
	margin: 0 auto;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		gap: var(--spacer-md);
		max-width: 100%;
	}
}

.item {
	grid-column: 1 / -1;
	margin-bottom: var(--spacer-sm);

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		grid-column: span 1 / span 1;
		margin-bottom: 0;
	}
}

.image {
	aspect-ratio: 900 / 506; // size of departure board
	background: rgba(white, 0.1);
	border-radius: 5px;
	margin-bottom: 0.25em;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		margin-bottom: var(--spacer-sm);
	}
}

.text {
	@include text-sm;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		@include text-base;
		font-size: 30px;
	}
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--gutter-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		position: static;
		display: flex;
		justify-content: center;
		padding: 0;
	}
}
