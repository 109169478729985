@import "../styles/global.scss";

.main {
	--ring-x: 580px;
	--ring-y: 460px;
	--ring-stroke-width: 20px;
	width: 100%;
	height: 100vh;
	height: 100svh;
	color: white;
	text-align: center;
	background-color: black;
	position: relative;
	display: flex;
	flex-direction: column;
}

.heading {
	text-align: center;
	align-self: flex-start;
	width: 100%;
	flex: 1;
	min-height: 200px;
	position: relative;
	z-index: 5;

	h1 {
		@include text-2xl;
		margin-top: 0.5em;
		text-transform: uppercase;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		h1 {
			@include text-3xl;
		}
	}
}

.canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.progress {
	width: var(--ring-x);
	height: var(--ring-y);
	stroke-dasharray: 1800;
	stroke-dashoffset: 1800;
	transform: rotateZ(-90deg);
	transform-origin: center center;
	stroke-width: var(--ring-stroke-width);
	stroke: $yellow;
	position: relative;
	z-index: 4;
	animation: draw 2s ease-in-out forwards;
}

@keyframes draw {
	0% {
		stroke-dashoffset: 1800;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.guide {
	width: var(--ring-x);
	height: var(--ring-y);
	stroke-dasharray: 0 0 20;
	stroke-dashoffset: 0;
	stroke-width: 8px;
	stroke: $red;
	opacity: 0.8;
	transform: rotateZ(-90deg);
	transform-origin: center center;
	position: absolute;
	z-index: 3;
}

.veil {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	object-fit: cover;
	transform: rotateY(180deg);
}

.action {
	width: 100%;
	align-self: flex-end;
	text-align: center;
	padding-bottom: 3rem;
	position: relative;
	z-index: 5;
	transition: opacity 0.25s;
	opacity: 1;
	> h2 {
		@include text-lg;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	> svg {
		transition: opacity 0.2s;
		display: inline-block;
	}
}

.flash {
	opacity: 0;
	position: absolute;
	object-fit: cover;
	background: white;
	--x: calc((var(--ring-y) - var(--ring-stroke-width) * 2) / 2);
	--y: calc((var(--ring-x) - var(--ring-stroke-width) * 2) / 2);
	clip-path: ellipse(var(--x) var(--y) at 50% 50%);
	width: 100%;
	height: 100%;
	z-index: 3;
	animation: flash 1s ease-in-out forwards;
	animation-delay: 1s;
}

@keyframes flash {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
