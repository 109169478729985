@import "../styles/typography.scss";
@import "../styles/spacing.scss";

.main {
	width: 100%;
	height: 100vh;
	height: 100svh;
	color: white;
	text-align: center;
	background-color: black;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: $spacer-lg;
	color: white;
	text-align: center;
	// optically adjustment to make it more center
	margin-top: -1%;

	h1 {
		@include text-2xl;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		h1 {
			@include text-3xl;
		}
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $spacer-lg;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		flex-direction: row;
	}
}

.qrCode {
	background-color: white;
	padding: 1rem;
	border-radius: 1em;
}

.note {
	@include text-base;
}

.mobileSwitch {
	@include text-base;
	text-transform: uppercase;
	text-decoration: underline;
	text-align: right;
}
