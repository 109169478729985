@import "../../../styles/typography.scss";

.main {
	@include druk;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: var(--button-height);
	color: black;
	font-size: 26px;
	line-height: 26px;
	letter-spacing: 1.26px;
	text-transform: uppercase;
	background: white;
	border-radius: 9999px;
	transition: color var(--transition);
	cursor: pointer;
	pointer-events: auto;
	&.disabled {
		background: #484848;
		cursor: not-allowed;
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		width: 200px;
		font-size: 42px;
		line-height: 42px;
	}
}
