@import "../../../styles/typography.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/media.scss";

.checkbox {
	margin-top: var(--spacer-sm);
	input {
		display: none;
	}
	input:checked + .checkboxLabel .checkboxLabel_a {
		border-color: rgba(white, 0.5);
	}
	input:checked + .checkboxLabel .checkboxLabel_a:after {
		opacity: 1;
		transform: scale(1);
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		margin-top: 0;
		margin-bottom: var(--spacer-sm);
	}
}

.checkboxLabel {
	display: flex;
	align-items: center;
	gap: 10px;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		gap: 20px;
	}
}

.checkboxLabel_a {
	width: 22px;
	height: 22px;
	border: 1px solid white;
	padding: 4px;
	border-radius: 4px;
	transition: border var(--transition);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: $yellow;
		opacity: 0;
		border-radius: 2px;
		transform: scale(0.8);
		transition:
			opacity var(--transition),
			transform var(--transition);
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		width: 34px;
		height: 34px;
		border: 2px solid white;
	}
}

.checkboxLabel_b {
	@include druk;
	font-size: 18px;
	text-transform: uppercase;
	cursor: pointer;
	text-transform: uppercase;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 26px;
	}
}
