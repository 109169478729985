.container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	color: black;
	padding: 2rem 0 2rem;
	background-color: #262626;
	transform: translateY(100%);
	transition: transform var(--transition);
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: transparent;
}

.clickThrough {
	pointer-events: none;
}

.visible {
	transform: translateY(0);
}

.keyboard {
	max-width: 1120px;
	margin: 0 auto;
	background-color: inherit;
	padding-left: 0;
	padding-right: 0;

	:global .hg-button {
		height: 60px;
		background-color: #525252;
		color: white;
		border-bottom: none;
		box-shadow: none;

		&[data-skbtn="@"],
		&[data-skbtn=".com"] {
			max-width: none !important;
		}

		&.hg-activeButton {
			color: black;
			background-color: #bbb !important;
		}
	}
}

.spaceKey {
	flex-grow: 1 !important;
}

.backspaceKey {
	background-color: #404040 !important;
	min-width: 132px;
}

.enterKey {
	background: #404040 !important;
	max-width: 182px;
}

.shiftKey {
	background: #404040 !important;
	width: 182px;
	flex-grow: 0 !important;
}

.shiftKeyOn {
	background: #2563eb !important;
	color: white;
	width: 182px;
	flex-grow: 0 !important;
}
