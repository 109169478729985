@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/spacing.scss";

.main {
	width: 100%;
	height: 100vh;
	height: 100svh;
	color: white;
	text-align: center;
	background-color: black;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.heading {
	text-align: center;

	h1 {
		@include text-2xl;
		text-transform: uppercase;
		margin-top: 0.5em;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		h1 {
			@include text-3xl;
		}
	}
}

.image {
	align-self: center;
	text-align: center;
	flex: 1;
	display: flex;
	align-items: center;
	> img {
		width: auto;
		height: 480px;
		display: inline-block;
	}
}

.action {
	min-height: 240px;
	> h2 {
		@include text-lg;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}
}
