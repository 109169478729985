@import "./fluid.scss";

$spacer-sm: #{fluid(10px, 20px)};
$spacer: #{fluid(15px, 30px)};
$spacer-md: #{fluid(20px, 40px)};
$spacer-lg: #{fluid(30px, 60px)};
$spacer-xl: #{fluid(40px, 80px)};
$spacer-2xl: #{fluid(50px, 100px)};
$spacer-3xl: #{fluid(60px, 120px)};
$spacer-4xl: #{fluid(80px, 160px)};
