@import "../styles/global.scss";

.main {
	--container-height: 100vh;
	--container-width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	width: var(--container-width);
	height: var(--container-height);
	overflow: hidden;
	pointer-events: none;
	z-index: 9999;
}

.inner {
	--width: calc(var(--container-width) * 2);
	--height: var(--container-height);
	--start: -100%;
	--center: calc(-50% + var(--container-width) / 2);
	--end: var(--container-width);
	position: absolute;
	top: 0;
	left: 0;
	width: var(--width);
	height: var(--height);
	transform: translateX(var(--start));
	.enter & {
		animation-duration: 2s;
		animation-fill-mode: forwards;
		animation-name: swipe;
	}
}

.svg {
	width: 100%;
	height: 100%;
	fill: $slate;
}

@keyframes swipe {
	0% {
		transform: translateX(var(--start));
	}
	50% {
		transform: translateX(var(--center));
	}
	100% {
		transform: translateX(var(--end));
	}
}
