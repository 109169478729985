@import "../styles/colors.scss";

.svg {
	animation: animateLoaderIcon 2s linear infinite;

	// sizes
	&.sm {
		width: 24px;
		height: 24px;
	}
	&.base {
		width: 36px;
		height: 36px;
	}
	&.lg {
		width: 48px;
		height: 48px;
	}
}

.circle {
	stroke-linecap: butt;
	animation: animateLoaderIconCircle 1.5s ease-in-out infinite;

	// colors
	.black & {
		stroke: black;
	}
	.white & {
		stroke: white;
	}
	.yellow & {
		stroke: $yellow;
	}
}

@keyframes animateLoaderIcon {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animateLoaderIconCircle {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -40;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
