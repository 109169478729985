@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/media.scss";

.main {
	input {
		display: none;
	}
	input:checked + .inner .checkbox {
		border-color: rgba(white, 0.5);
	}
	input:checked + .inner .checkbox:after {
		opacity: 1;
		transform: scale(1);
	}
}

.inner {
	display: flex;
	align-items: center;
	gap: 10px;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		gap: 20px;
	}
}

.checkbox {
	width: 22px;
	height: 22px;
	border: 1px solid white;
	padding: 4px;
	border-radius: 4px;
	transition: border var(--transition);
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: $yellow;
		opacity: 0;
		border-radius: 2px;
		transform: scale(0.8);
		transition:
			opacity var(--transition),
			transform var(--transition);
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		width: 34px;
		height: 34px;
		border: 2px solid white;
	}
}

.label {
	@include druk;
	font-size: 18px;
	text-transform: uppercase;
	cursor: pointer;
	text-transform: uppercase;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 26px;
	}
}
