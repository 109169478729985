.main {
	min-width: 10em;
	min-height: 2em;
	padding: 1em;
	background: transparent;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	text-align: right;
}
