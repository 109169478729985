@import "../styles/typography.scss";

.main {
	width: 100%;
	height: 100vh;
	height: 100svh;
	color: white;
	text-align: center;
	background-color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--gutter-y) var(--gutter-x);
	--gutter-x: 20px;
	--gutter-y: 20px;
}

.content {
	text-align: center;
	h1 {
		@include druk;
		font-size: 36px;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		h1 {
			font-size: 68px;
		}
	}
}

.copy {
	@include text-lg;
	line-height: 1.5em;
	margin-bottom: 1em;

	pre {
		text-transform: none;
	}
}

.loader {
	margin: 0 auto;
}
