@import "../../styles/typography.scss";
@import "../../styles/colors.scss";

.main {
	width: 100%;
	display: flex;
	align-items: center;
}

.back {
	@include text-sm;
	display: none;
	align-items: center;
	gap: 10px;
	height: 44px;
	font-size: 16px;
	color: #eee;
	text-transform: uppercase;
	cursor: pointer;
	pointer-events: auto;
	svg {
		width: 16px;
		fill: #eee;
		margin-bottom: 2px; // slightly better vertical alignment
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		@include text-base;
		display: flex;
	}
}

.actions {
	width: 100%;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (min-width: $q-breakpoint) {
		justify-content: flex-end;
	}
}

.next {
	width: 100%;
}

.examples {
	@include text-sm;
	text-transform: uppercase;
	text-decoration: underline;
	width: 100%;
	text-align: left;
	margin-right: 1em;
	@media (min-width: $q-breakpoint) {
		width: auto;
		@include text-base;
	}
}
