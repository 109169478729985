@import "../styles/spacing.scss";
@import "../styles/typography.scss";
@import "../styles/media.scss";
@import "../styles/colors.scss";

.mask {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: rgba(black, 0.85);
	backdrop-filter: blur(1px);

	z-index: 8888;
}

.main {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	height: 100svh;

	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	z-index: 9999;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		padding: $spacer-3xl;
	}
}

.inner {
	position: relative;
	width: 100%;
	min-height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	border-radius: 20px;
	background: black;
	box-shadow: 0 4px 54px 0 rgba(0, 0, 0, 0.55);
	padding: $spacer-md;
	gap: $spacer-md;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		align-items: center;
		justify-content: center;
		padding: $spacer-3xl;
	}
}

.close {
	position: absolute;
	top: 1em;
	right: 1em;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px solid white;
	border-radius: 9999px;
	svg {
		fill: white;
		width: 14px;
		height: 14px;
	}

	@media (min-width: $q-breakpoint) {
		top: 2em;
		right: 2em;
		width: 50px;
		height: 50px;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: $spacer-lg;

	color: white;
	text-align: center;

	width: 100%;

	h1 {
		@include druk;
		font-size: 36px;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		h1 {
			font-size: 68px;
		}
	}
}

.timer {
	@include druk;
	font-size: 120px;
	line-height: 85%;
	color: $yellow;
	text-align: center;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 287px;
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $spacer-md;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		flex-direction: row;
	}
}
