@import "../../../styles/typography.scss";
@import "../../../styles/colors.scss";

.main {
	--main-height: calc(100vh - var(--progress-height));
	--mask-height: calc(var(--button-height) + (var(--gutter-y) * 2));
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	align-items: center;
}

.head {
	padding: 0 var(--gutter-x);
	height: 100px;

	.isInModal & {
		padding: 0;
	}
}

.inner {
	padding: var(--spacer-sm) var(--gutter-x) calc(var(--mask-height) * 1.8);
	height: var(--main-height);
	overflow-y: auto;
	.isInModal & {
		padding: var(--spacer-sm) 0;
		height: auto;
	}
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--gutter-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;
}

.options {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;

	.isInModal & {
		gap: 2em;
	}
}

.option {
	display: block;
	cursor: pointer;
	text-align: left;
	transition: opacity var(--transition);

	@include text-xl;
	.alt & {
		@include text-lg;
	}

	svg {
		width: 25px;
		fill: white;
	}

	&.selected {
		color: $yellow;
	}

	&.dismissed {
		opacity: 0.5;
	}
}

.mask {
	position: fixed;
	bottom: 0;
	left: 0;
	height: var(--mask-height);
	width: 100%;
	background: pink;
	pointer-events: none;
	z-index: 10;
	backdrop-filter: blur(2px);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.3) 0%,
		rgba(0, 0, 0, 1) 100%
	);
}
