@import "../styles/typography.scss";

.main {
	--gutter-y: 60px;
	--gutter-x: 20px;

	width: 100%;
	height: 100svh;
	color: white;
	text-align: center;
	background-color: black;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: var(--gutter-y) var(--gutter-x);
	text-transform: uppercase;
	@include text-base;

	background-image: url("../assets/mobile-bg.png");
	background-size: cover;
	background-position: bottom center;
	background-color: black;
	background-repeat: no-repeat;
}

.topText {
	text-wrap: balance;
	max-width: 70%;
}

.minutes {
	display: flex;
	flex-direction: column;
	@include text-2xl;
	text-transform: uppercase;
}

.minutesNumber {
	@include text-4xl;
	line-height: 70%;
}

.minutesText {
	@include neusa;
	font-size: 42px;
}

.stateText {
	@include druk;
	font-size: 130px;
	line-height: 110px;
}

.stateText > span {
	background-image: linear-gradient(
		to right,
		hsla(68, 100%, 50%, 1) 70.5%,
		white 30%
	);
	background-clip: text;
	color: transparent;
}

.processing {
	@include druk;
	font-size: 80px;
}
