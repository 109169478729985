@import "../../../styles/typography.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/media.scss";

.input {
	@include druk;
	font-size: 36px;
	line-height: 36px;
	width: 100%;
	border: none;
	border-bottom: 1px solid rgba(white, 0.25);
	outline: none;
	appearance: none;
	padding: var(--spacer-sm) 0;
	transition: border var(--transition);
	appearance: none;
	border-radius: 0;
	&::placeholder {
		color: rgba(white, 0.25);
		transition: opacity var(--transition);
	}
	&:focus {
		&::placeholder {
			opacity: 0.5;
		}
	}
	&.error {
		border-color: $red;
	}
	&.valid {
		border-color: $yellow;
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 110px;
		line-height: 110px;
		padding: 0;
	}
}

.message {
	@include neusa;
	font-size: 12px;
	text-transform: uppercase;
	color: $red;
	pointer-events: none;
	display: none;
	align-items: center;
	padding-top: var(--spacer-sm);
	transition: opacity var(--transition);
	&.error {
		display: flex;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		display: flex;
		opacity: 0;
		font-size: 22px;
		height: var(--spacer-md);
		padding: 0;
		&.error {
			opacity: 1;
		}
	}
}
